.FP-Intro{
    margin:128px 0;
    .image{
        position:relative;
        img{
            margin:0 auto;
            display:block;
            width:150px;
            //background:#333;
            height:500px;
            z-index:1;
            position:relative;
            transition:0.3s all ease-out;
        }
        .hex{
            width:300px;
            height:300px;
            max-width: 100%;
            position:relative;
            //background:linear-gradient(rgba(#222,0),#222);
            margin:0 auto;
            clip-path:polygon(0 30%,0 70%, 30% 100%, 70% 100%, 100% 70%,100% 30%, 70% 0, 30% 0);
            //position:absolute;
            //bottom:0;
            //left:50%;
            //transform:translateX(-50%);
            img{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }
    }
    .text{
        transition:0.3s all ease-out 0.15s;
    }
    &:not(.is-visible){
        .image img,.text{
            opacity:0;
        }
    }
}
@media print{
    body .FP-Intro{
        margin:32px 0;
        .image img,.text{
            opacity:1 !important;
        }
        .row>div{
            display:block !important;
            &.col-img{
                width:33%;
                .hex{
                    width:150px;
                    height:150px !important;
                }
            }
            &.col-text{
                width:66%;
            }
        }
    }
}