.FP-Ability{
    max-width:250px;
    margin:0 auto;
    .hex{
        //background:purple;
        background:var(--background-soft);
        width:100%;
        height:0;
        padding-top:59%;
        clip-path:polygon(0 100%, 0% 49%, 30% 0%, 70% 0%, 100% 49%, 100% 100%, 86% 100%, 86% 64%, 64% 28%, 36% 28%, 14% 65%, 14% 100%);
        position:relative;
        overflow:hidden;
        .inner{
            position:absolute;
            bottom:0;
            right:50%;
            transform:translateX(50%);
            width:200%;
            height:200%;
            transform-origin: bottom center;
            transition:0.5s all ease-out 0.5s;
        }
        /*&.level-1 .inner{clip-path: polygon(50% 100%, 0 100%, 0% 49%);}
        &.level-2 .inner{clip-path: polygon(50% 100%, 0 100%, 0 0, 35% 0);}
        &.level-3 .inner{clip-path: polygon(50% 100%, 0 100%, 0 0, 65% 0);}
        &.level-4 .inner{clip-path: polygon(50% 100%, 0 100%, 0 0, 100% 0, 100% 51%);}*/
        &.level-1 .inner{transform:translateX(50%) rotate(-150deg)}
        &.level-2 .inner{transform:translateX(50%) rotate(-110deg)}
        &.level-3 .inner{transform:translateX(50%) rotate(-71deg)}
        &.level-4 .inner{transform:translateX(50%) rotate(-30deg)}
        &.level-5 .inner{/*clip-path: polygon(50% 100%, 0 100%, 0% 25%);*/}
        .bar{
            width:43%;
            height:40px;
            position:absolute;
            top:0;
            left:50%;
            transform:translateX(-50%);
            &.bar1{
                bottom:0;
                top:auto;
                left:0;
                //transform-origin: bottom left;
                //transform:rotate(-90deg) translateY(100%);
                transform:rotate(-90deg) translateY(-100%);
            }
            &.bar2{
                transform-origin: 10% 49%;
                transform:translateX(-50%) rotate(-225deg);
            }
            &.bar4{
                transform-origin: 90% 49%;
                transform:translateX(-50%) rotate(225deg);
            }
            &.bar5{
                bottom:0;
                top:auto;
                left:auto;
                right:0;
                //transform-origin: bottom left;
                //transform:rotate(-90deg) translateY(100%);
                transform:rotate(90deg) translateY(-100%);
            }
        }
        &.level-1{
            .bar2,.bar3,.bar4,.bar5{background:#888 !important;}
        }
        &.level-2{
            .bar3,.bar4,.bar5{background:#888 !important;}
        }
        &.level-3{
            .bar4,.bar5{background:#888 !important;}
        }
        &.level-4{
            .bar5{background:#888 !important;}
        }
    }
    .FP-Image img{
        max-width:45%;
        height:60px;
        object-fit: contain;
        margin:-25% auto 20px;
        display:block;
        transition:0.3s all ease-out 0.3s;
    }
    h3,p{
        text-align:center;
        transition:0.3s all ease-out 0.45s;
    }
    &:not(.is-visible){
        .FP-Image img,h3,p{opacity:0;}
        .hex .inner{
            transform:translateX(50%) rotate(-179deg);
        }
    }
}
@media print{
    .FP-Ability{
        .FP-Image img,h3,p{opacity:1 !important;}
        .hex .inner{
            transform: none !important;
        }
    }
}