.FP-Formation{
    margin:128px 0;
    .row>div{
        position:relative;
    }
    h2,.formation{
        transition:0.5s all ease-out;
    }
    .formation{
        &:nth-child(1){transition-delay:0.15s;}
        &:nth-child(2){transition-delay:0.30s;}
        &:nth-child(3){transition-delay:0.45s;}
        &:nth-child(4){transition-delay:0.60s;}
        &:nth-child(5){transition-delay:0.75s;}
    }
    &:not(.is-visible){
        h2,.formation{
            opacity:0;
        }
    }
}
@media print{
    .FP-Formation{
        margin:32px 0;
        h2,.formation{
            opacity:1 !important;
        }
        .row>.formation{
            width:50%;
            .col-sm-4{
                width:33%;
            }
            .col-sm-8{
                width:66%;
            }
        }
    }
}