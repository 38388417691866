.FP-Loading{
    width:100%;
    height:100vh;
    position:fixed;
    z-index:1000;
    /*display:flex;
    align-items:center;
    justify-content: center;*/
    font-weight:700;
    font-size:52px;
    line-height:1.25em;
    color:var(--text);
    background:var(--background);
    //transition:0.3s all ease-out;
    box-shadow: -18px 0px 0px 18px var(--text);
    >div{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        text-align:center;
        >div{
            display:inline-block;
        }
    }
    .chargement,.name{
        min-width:350px;
    }
    .chargement{opacity:0;}
    .name{
        text-align:right;
        opacity:0;
    }
    .title{
        font-size:20px;
        font-weight:400;
        line-height:1em;
        text-transform:uppercase;
        height:0;
        overflow:hidden;
        opacity:0;
    }
    &.is-done{
        animation-name: animbg;
        .chargement{animation-name: animloading;}
        .name{animation-name: animname;}
        .title{animation-name: animtitle;}
    }
}
.FP-Loading, .FP-Loading div{
    animation-fill-mode: forwards;
    animation-duration: 4s;
}

@keyframes animbg {
    0%{clip-path:polygon(0% 0, 130% 0, 100% 100%, -30% 100%);}
    75%{clip-path:polygon(0% 0, 130% 0, 100% 100%, -30% 100%);}
    100%{clip-path:polygon(130% 0, 260% 0, 230% 100%, 100% 100%);}
}
@keyframes animloading {
    0%{clip-path:polygon(0% 0, 130% 0, 100% 100%, -30% 100%);}
    25%{clip-path:polygon(130% 0, 260% 0, 230% 100%, 100% 100%);}
    100%{clip-path:polygon(130% 0, 260% 0, 230% 100%, 100% 100%);}
}
@keyframes animname {
    0%{clip-path:polygon(-130% 0, 0% 0, -30% 100%, -130% 100%);opacity:0;}
    1%{clip-path:polygon(-130% 0, 0% 0, -30% 100%, -130% 100%);opacity:1;}
    25%{clip-path:polygon(0% 0, 130% 0, 100% 100%, -30% 100%);opacity:1;}
    100%{clip-path:polygon(0% 0, 130% 0, 100% 100%, -30% 100%);opacity:1;}
}
@keyframes animtitle {
    0%{height:0;}
    25%{height:0;opacity:0}
    50%{height:30px;opacity:1;}
    100%{height:30px;opacity:1;}
}