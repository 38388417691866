.FP-Project{
    margin:64px 0;
    overflow:hidden;
    h3{
        margin-bottom:32px;
    }
    .row{
        --bs-gutter-x:64px;
        --bs-gutter-y:64px;
    }
    .flex-row-reversed{
        flex-direction: row-reverse;
    }
    a{
        color:var(--text);
    }
    .text,.image{
        transition:0.5s all ease-out;
    }
    &:not(.is-visible){
        .text{
            transform:translateX(-50%);
            opacity:0;
        }
        .image{
            transform:translateX(+50%);
            opacity:0;
        }
        .flex-row-reversed{
            .text{
                transform:translateX(+50%);
                opacity:0;
            }
            .image{
                transform:translateX(-50%);
                opacity:0;
            }
        }
    }
}

@media print{
    .FP-Project{
        .text,.image{
            opacity:1;
            transform:none !important;
        }
    }
}