.FP-Contact{
    margin:124px 0;
    text-align: center;
    transition:0.5s all ease-out;
    &:not(.is-visible){
        opacity:0;
    }
}
@media print{
    .FP-Contact{
        opacity:1 !important;
        margin:32px 0;
    }
}