.FP-LightModeSwitcher{
    position:fixed;
    bottom:12px;
    left:12px;
    width:64px;
    height:64px;
    border:1px solid var(--text);
    background:var(--background);
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius:999vw;
    transition:0.3s all ease-out;
    cursor:pointer;
    &:hover{
        background:var(--text);
        color:var(--background);
    }
    svg{
        max-width:40%;
    }
}

@media print{
    .FP-LightModeSwitcher{display:none;}
}