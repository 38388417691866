.FP-Job{
    .hexcontainer{
        max-width:250px;
        margin:0 auto;
    }
    .hex{
        width:100%;
        padding-top:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        background:#222;
        -webkit-print-color-adjust: exact;
        //position:absolute;
        position:relative;
        //background:linear-gradient(rgba(#222,0),#222);
        clip-path:polygon(0 30%,0 70%, 30% 100%, 70% 100%, 100% 70%,100% 30%, 70% 0, 30% 0);
        //position:absolute;
        //float:right;
        img{
            max-width:60%;
            max-height:60%;
            margin:0 auto;
            text-align:center;
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
        }
    }
    h3{
        margin-top:0;
    }
    p{
        margin:0;
    }
}